import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueApollo from "vue-apollo";
import apolloClient from "./graphql/client";
import VueMoment from "vue-moment";
import moment from "moment";
import i18n from "./i18n";
import VueVideoPlayer from "vue-video-player";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import "./filters";

import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "fontsource-roboto/index.css";

if (window.appSettings && window.appSettings.defaultLocale) {
  i18n.locale = window.appSettings.defaultLocale;
}

moment.locale("et");

Vue.use(Vuex);
Vue.use(VueApollo);
Vue.use(VueMoment, { moment });
Vue.use(VueVideoPlayer);

Vue.config.productionTip = false;

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

if (process.env.NODE_ENV != "development") {
  Sentry.init({
    Vue,
    dsn:
      "https://69478727c3c142738311c95402fc9619@o4504453142544384.ingest.sentry.io/4504453231869952",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [
          "safetyportal.whyflow.ee",
          "eeraksrv04.jwinc.jeld-wen.com",
          /^\//,
        ],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });

  Sentry.setUser(store.state.me ? store.state.me.id : undefined);
}

new Vue({
  vuetify,
  router,
  store,
  apolloProvider,
  i18n,
  render: (h) => h(App),
  created() {
    this.$i18n.locale = this.$store.state.locale;
  },
}).$mount("#app");
