var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"toolbar",staticStyle:{"height":"64px"},attrs:{"app":"","dark":"","flat":"","color":"transparent"},scopedSlots:_vm._u([(
      _vm.isMainView &&
        (_vm.role(
          'admin',
          'manager',
          'departmentManager',
          'safetyRepresentative'
        ) ||
          _vm.mentorshipActionCount ||
          _vm.riskAnalysisActionsRemaining !== null)
    )?{key:"extension",fn:function(){return [_c('v-card',{staticClass:"toolbar-bottom-rounded",attrs:{"light":"","width":"100%"}},[(_vm.isAuthenticated)?_c('v-tabs',{staticClass:"mx-3",attrs:{"show-arrows":""}},[_c('v-tab',{attrs:{"to":"/profile","data-test":"toolb-profile"}},[_vm._v("\n          "+_vm._s(_vm.$t("profile.title"))+"\n        ")]),(
            _vm.role(
              'admin',
              'manager',
              'departmentManager',
              'safetyRepresentative'
            )
          )?_c('v-tab',{attrs:{"to":"/employees","data-test":"toolb-employees"}},[_vm._v("\n          "+_vm._s(_vm.$t("employees.title"))+"\n        ")]):_vm._e(),(
            _vm.role(
              'admin',
              'manager',
              'departmentManager',
              'safetyRepresentative'
            )
          )?_c('v-tab',{attrs:{"to":"/instructions","data-test":"toolb-instructions"}},[_vm._v("\n          "+_vm._s(_vm.$t("instructions.title"))+"\n        ")]):_vm._e(),(_vm.role('admin', 'safetyRepresentative'))?_c('v-tab',{attrs:{"to":"/external-trainings"}},[_vm._v("\n          "+_vm._s(_vm.$t("extTrainings.title"))+"\n        ")]):_vm._e(),(_vm.mentorshipActionCount)?_c('v-tab',{attrs:{"to":"/mentorship"}},[(_vm.mentorshipActionCount)?_c('v-badge',{attrs:{"content":_vm.mentorshipActionCount}},[_vm._v("\n            "+_vm._s(_vm.$t("mentorship.title"))+"\n          ")]):_vm._e()],1):_vm._e(),(
            _vm.role(
              'admin',
              'manager',
              'departmentManager',
              'safetyRepresentative'
            ) || _vm.riskAnalysisActionsRemaining !== null
          )?_c('v-tab',{attrs:{"to":"/risk-analysis"}},[_c('v-badge',{attrs:{"value":_vm.riskAnalysisActionsRemaining,"content":_vm.riskAnalysisActionsRemaining}},[_vm._v("\n            "+_vm._s(_vm.$t("riskAnalysis.title"))+"\n          ")])],1):_vm._e(),(
            _vm.role(
              'admin',
              'safetyRepresentative',
              'manager',
              'departmentManager'
            )
          )?_c('v-tab',{attrs:{"color":"primary","to":"/reports"}},[_vm._v("\n          "+_vm._s(_vm.$t("reports.title"))+"\n        ")]):_vm._e(),(
            _vm.role(
              'admin',
              'safetyRepresentative',
              'manager',
              'departmentManager'
            )
          )?_c('v-tab',{attrs:{"to":"/management"}},[_vm._v("\n          "+_vm._s(_vm.$t("management.title"))+"\n        ")]):_vm._e()],1):_vm._e()],1)]},proxy:true}:null],null,true)},[_c('v-toolbar-title',[_c('v-img',{staticClass:"mr-2",attrs:{"src":"/files/app/logo.svg","contain":true}})],1),_c('v-spacer'),(_vm.isAuthenticated)?_c('v-toolbar-items',[_c('v-row',{staticClass:"pr-2 ma-0",attrs:{"align":"center"}},[_c('div',{staticClass:"text-start"},[_c('div',{staticClass:"subtitle-1"},[_c('span',{attrs:{"data-test":"toolb-first-name"}},[_vm._v(_vm._s(_vm.me.firstName))]),_vm._v("\n          "+_vm._s(" ")+"\n          "),_c('span',{attrs:{"data-test":"toolb-last-name"}},[_vm._v(_vm._s(_vm.me.lastName))])]),_c('div',{staticClass:"subtitle-2"},[_c('span',[_vm._v(_vm._s(_vm.$t(("role." + (_vm.me.role)))))])])])])],1):_vm._e(),_c('v-toolbar-items',[_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-row',{staticClass:"ma-0 locale-row",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-select',{staticClass:"no-border text-uppercase",staticStyle:{"min-width":"105%"},attrs:{"items":_vm.locales,"hide-details":""},model:{value:(_vm.locale),callback:function ($$v) {_vm.locale=$$v},expression:"locale"}})],1)],1),(_vm.isSupportDialogEnabled)?[_c('v-divider',{staticClass:"ml-4 mr-6",attrs:{"vertical":""}}),_c('v-toolbar-items',[_c('support-dialog')],1)]:_vm._e(),_c('v-divider',{staticClass:"ml-4 mr-6",attrs:{"vertical":""}})],2),(_vm.isAuthenticated)?_c('v-toolbar-items',[_c('v-btn',{attrs:{"text":"","data-test":"toolb-logout"},on:{"click":_vm.logout}},[_vm._v(_vm._s(_vm.$t("auth.logout")))])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }