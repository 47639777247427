<template>
  <div>
    <v-snackbar
      v-for="(message, i) in globalMessages"
      :key="`message-${i}`"
      :value="true"
      :timeout="timeout * globalMessages.length"
      :color="message.color"
    >
      <span>{{ message.text }}</span>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Toasts",
  props: {
    timeout: {
      type: Number,
      default: 6000
    }
  },
  computed: {
    ...mapState(["globalMessages"])
  },
  watch: {
    globalMessages: function() {
      if (this.globalMessages.length > 0) {
        setTimeout(
          () => this.$store.commit("remove_global_message"),
          this.timeout
        );
      }
    }
  }
};
</script>
