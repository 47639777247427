<template>
  <v-app-bar
    class="toolbar"
    app
    dark
    flat
    style="height: 64px"
    color="transparent"
  >
    <v-toolbar-title>
      <v-img src="/files/app/logo.svg" :contain="true" class="mr-2"></v-img>
    </v-toolbar-title>

    <v-spacer></v-spacer>
    <v-toolbar-items v-if="isAuthenticated">
      <v-row align="center" class="pr-2 ma-0">
        <div class="text-start">
          <div class="subtitle-1">
            <span data-test="toolb-first-name">{{ me.firstName }}</span>
            {{ " " }}
            <span data-test="toolb-last-name">{{ me.lastName }}</span>
          </div>
          <div class="subtitle-2">
            <span>{{ $t(`role.${me.role}`) }}</span>
          </div>
        </div>
      </v-row>
    </v-toolbar-items>
    <v-toolbar-items>
      <v-divider vertical class="mx-4"></v-divider>
      <v-row align="center" class="ma-0 locale-row">
        <v-col cols="auto">
          <v-select
            v-model="locale"
            :items="locales"
            hide-details
            style="min-width: 105%"
            class="no-border text-uppercase"
          ></v-select>
        </v-col>
      </v-row>
      <template v-if="isSupportDialogEnabled">
        <v-divider vertical class="ml-4 mr-6"></v-divider>
        <v-toolbar-items>
          <support-dialog />
        </v-toolbar-items>
      </template>
      <v-divider vertical class="ml-4 mr-6"></v-divider>
    </v-toolbar-items>
    <v-toolbar-items v-if="isAuthenticated">
      <v-btn text @click="logout" data-test="toolb-logout">{{
        $t("auth.logout")
      }}</v-btn>
    </v-toolbar-items>
    <template
      v-if="
        isMainView &&
          (role(
            'admin',
            'manager',
            'departmentManager',
            'safetyRepresentative'
          ) ||
            mentorshipActionCount ||
            riskAnalysisActionsRemaining !== null)
      "
      v-slot:extension
    >
      <v-card light width="100%" class="toolbar-bottom-rounded">
        <v-tabs v-if="isAuthenticated" show-arrows class="mx-3">
          <v-tab to="/profile" data-test="toolb-profile">
            {{ $t("profile.title") }}
          </v-tab>

          <v-tab
            v-if="
              role(
                'admin',
                'manager',
                'departmentManager',
                'safetyRepresentative'
              )
            "
            to="/employees"
            data-test="toolb-employees"
          >
            {{ $t("employees.title") }}
          </v-tab>

          <v-tab
            v-if="
              role(
                'admin',
                'manager',
                'departmentManager',
                'safetyRepresentative'
              )
            "
            to="/instructions"
            data-test="toolb-instructions"
          >
            {{ $t("instructions.title") }}
          </v-tab>

          <v-tab
            v-if="role('admin', 'safetyRepresentative')"
            to="/external-trainings"
          >
            {{ $t("extTrainings.title") }}
          </v-tab>

          <v-tab v-if="mentorshipActionCount" to="/mentorship">
            <v-badge
              v-if="mentorshipActionCount"
              :content="mentorshipActionCount"
            >
              {{ $t("mentorship.title") }}
            </v-badge>
          </v-tab>

          <v-tab
            v-if="
              role(
                'admin',
                'manager',
                'departmentManager',
                'safetyRepresentative'
              ) || riskAnalysisActionsRemaining !== null
            "
            to="/risk-analysis"
          >
            <v-badge
              :value="riskAnalysisActionsRemaining"
              :content="riskAnalysisActionsRemaining"
            >
              {{ $t("riskAnalysis.title") }}
            </v-badge>
          </v-tab>

          <v-tab
            v-if="
              role(
                'admin',
                'safetyRepresentative',
                'manager',
                'departmentManager'
              )
            "
            color="primary"
            to="/reports"
          >
            {{ $t("reports.title") }}
          </v-tab>

          <v-tab
            v-if="
              role(
                'admin',
                'safetyRepresentative',
                'manager',
                'departmentManager'
              )
            "
            to="/management"
          >
            {{ $t("management.title") }}
          </v-tab>
        </v-tabs>
      </v-card>
    </template>
  </v-app-bar>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import SupportDialog from "@/components/SupportDialog.vue";

export default {
  components: {
    SupportDialog,
  },

  props: ["pathName", "isMainView"],

  data() {
    return {
      isSupportDialogEnabled:
        window.appSettings &&
        window.appSettings.isSupportDialogEnabled !== undefined
          ? window.appSettings.isSupportDialogEnabled
          : true,
    };
  },
  computed: {
    ...mapState([
      "me",
      "mentorshipActionCount",
      "riskAnalysisActionsRemaining",
    ]),
    ...mapGetters(["isAuthenticated", "role", "locales"]),
    locale: {
      get() {
        return this.$store.state.locale;
      },
      set(loc) {
        this.$store.dispatch("change_locale", loc).then(() => {
          const currentRoute = this.$router.currentRoute.name;
          // don't refresh on specified routes
          if (currentRoute == "login" || currentRoute == "passwordChange") {
            return;
          }
          // refresh to reload data with the new language
          this.$router.go();
        });
      },
    },
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style scoped>
.toolbar
  >>> .no-border.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-style: none;
}
.toolbar
  >>> .no-border.v-text-field
  > .v-input__control
  > .v-input__slot:after {
  border-style: none;
}
.toolbar
  >>> .no-border.v-text-field
  > .v-input__control
  > .v-text-field__details {
  display: none;
}
.toolbar >>> .v-toolbar__extension {
  height: 48px !important;
  background-color: transparent !important;
  padding: 4px 12px !important;
}
.toolbar >>> .v-toolbar__content {
  height: 64px !important;
  background-color: var(--primary-color);
  z-index: 5;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.toolbar-bottom-rounded {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  height: 48px;
}
.toolbar >>> .v-select__selections input {
  display: none;
}
.toolbar >>> .locale-row:hover {
  background-color: rgba(255, 255, 255, 0.08);
}
</style>
