<template>
  <v-dialog
    v-model="show"
    max-width="600px"
    @click:outside="close()"
    @keydown.esc="close()"
    ><template v-slot:activator="{ on }">
      <v-btn v-on="on" text>
        <v-icon left>mdi-message-alert-outline</v-icon>
        <span class="hidden-xs-only">{{ $t("auth.support") }}</span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">{{
        $t("auth.supportTitle")
      }}</v-card-title>
      <v-card-text>
        <div style="padding-top: 10px; padding-bottom: 20px;">
          {{ $t("auth.supportText") }}
        </div>
        <v-textarea
          v-if="!isAuthenticated"
          v-model="authorName"
          :label="$t('auth.supportName')"
          required
          rows="1"
          auto-grow
        />
        <v-textarea
          v-model="problemDescription"
          :label="$t('auth.supportTextField')"
          required
          rows="1"
          auto-grow
        />
        <v-file-input
          v-model="file"
          chips
          :label="$t('auth.supportFile')"
          prepend-icon="mdi-camera"
          accept=".jpg,.jpeg,.png,.gif,.bmp"
        ></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="close()">{{
          $t("dialog.cancel")
        }}</v-btn>
        <v-btn
          color="primary darken-1"
          text
          @click="confirm()"
          :disabled="disabled"
          >{{ $t("dialog.send") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import SubmitSupportRequest from "@/graphql/mutation/SubmitSupportRequest.gql";
export default {
  data() {
    return {
      show: false,
      problemDescription: "",
      file: null,
      loading: false,
      authorName: null,
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    ...mapState(["me"]),
    disabled() {
      if (!this.isAuthenticated && !this.authorName) {
        return true;
      }
      return this.problemDescription === "";
    },
  },
  beforeDestroy() {
    this.resetData();
  },
  methods: {
    confirm() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: SubmitSupportRequest,
          variables: {
            authorName: this.authorName,
            problemDescription: this.problemDescription,
            file: this.file,
          },
        })
        .then(() => {
          this.$store.commit("push_global_message", {
            color: "success",
            text: this.$i18n.t("auth.supportSuccess"),
          });
          this.close();
        })
        .catch((err) => {
          this.$store.commit("push_global_message", {
            color: "error",
            text: `${this.$i18n.t("auth.supportFail")} ${err}`,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    close() {
      this.show = false;
      this.resetData();
    },
    resetData() {
      this.problemDescription = "";
      this.file = null;
      this.authorName = null;
    },
  },
};
</script>

<style>
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>
