import Vue from "vue";
import moment from "moment";
import i18n from "./i18n";

Vue.filter("date", (value) =>
  value ? moment(value).format("DD.MM.YYYY") : ""
);

Vue.filter("dateTime", (value) =>
  value ? moment(value).format("DD.MM.YYYY H:mm:ss") : ""
);

Vue.filter("dateTimeWithoutSeconds", (value) =>
  value ? moment(value).format("DD.MM.YYYY H:mm") : ""
);

Vue.filter("timeDuration", (seconds) => {
  // has to be in UTC, otherwise it will be off by utc and local difference
  const m = moment.unix(seconds).utc();
  const format = m.hours() > 0 ? "H:mm:ss" : "m:ss";
  return seconds ? m.format(format) : "";
});

Vue.filter("minutesHours", (seconds) => {
  // has to be in UTC, otherwise it will be off by utc and local difference
  const m = moment.unix(seconds).utc();
  if (m.hours() > 0) {
    return seconds ? m.format("H") + " h" : "";
  } else if (m.minutes() > 0) {
    return seconds ? m.format("m") + " min" : "";
  } else {
    return seconds ? m.format("s") + " s" : "";
  }
});

Vue.filter("hexToRgb", (hex, opacity) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}, ${opacity || 1})`
    : `rgba(0, 0, 0, ${opacity || 1})`;
});

Vue.filter(
  "name",
  (value) =>
    value &&
    value.firstName &&
    value.lastName &&
    `${value.firstName} ${value.lastName}`
);

Vue.filter("nameId", (value) =>
  value && value.id && value.firstName && value.lastName
    ? `${value.firstName} ${value.lastName} (${value.id})`
    : ""
);

Vue.filter("id", (id) => (+id < 1000000 ? id : ""));
Vue.filter("idBrackets", (id) => (+id < 1000000 ? `(${id})` : ""));

Vue.filter("translate", (locales) => {
  if (!Array.isArray(locales) || locales.length === 0) {
    return `${locales}`;
  }

  const locale = i18n.locale;
  const defaultLocale = window.appSettings && window.appSettings.defaultLocale;

  const matchedItem =
    locales.find((item) => item.language === locale) ||
    locales.find((item) => item.language === defaultLocale) ||
    locales[0];

  return matchedItem.text;
});
