import Vue from "vue";
import Vuex from "vuex";
import i18n from "./i18n";
import client from "./graphql/client";
import LoginQuery from "./graphql/Login.gql";
import ChangeLanguageQuery from "./graphql/ChangeLanguage.gql";
import EmployeeRiskAnalysisActionsRemainingQuery from "./graphql/queries/employee/EmployeeRiskAnalysisActionsRemaining.gql";
import router from "./router";

Vue.use(Vuex);

const defaultLocale =
  (window.appSettings && window.appSettings.defaultLocale) || "en";

const store = (key, value) =>
  typeof value !== "undefined"
    ? localStorage.setItem(key, value)
    : localStorage.removeItem(key);

const restore = (key) => localStorage.getItem(key);

export default new Vuex.Store({
  state: {
    token: restore("token"),
    me: JSON.parse(restore("me")),
    locale: restore("locale") || defaultLocale,
    employeeFilters: JSON.parse(restore("employeeFilters")) || [],
    employeeHeaders: JSON.parse(restore("employeeHeaders")) || [],
    showAll: {
      departments: restore("showAllEmployees") === "true",
      shifts: restore("showAllShifts") === "true",
    },
    mentorshipActionCount: restore("mentorshipActionCount"),
    riskAnalysisActionsRemaining: null,
    passwordChangeRequired: restore("passwordChangeRequired") === "true",
    globalMessages: [],
    locales: [
      {
        value: "et",
        text: "Eesti",
      },
      {
        value: "ru",
        text: "Русский",
      },
      {
        value: "en",
        text: "English",
      },
      {
        value: "fi",
        text: "Suomi",
      },
      {
        value: "lv",
        text: "Latviešu",
      },
    ],
  },

  mutations: {
    auth_success(state, session) {
      store("token", (state.token = session.token));
      store("me", JSON.stringify((state.me = session.employee)));
      store("locale", (i18n.locale = state.locale = state.me.language));
      store(
        "passwordChangeRequired",
        (state.passwordChangeRequired = session.passwordChangeRequired)
      );
    },
    change_locale(state, locale) {
      i18n.locale = state.locale = locale;
      if (state.me) {
        state.me.language = locale;
        store("me", JSON.stringify(state.me));
      }
      store("locale", state.locale);
    },
    change_employee_filters(state, filters) {
      const employeeFilters = state.employeeFilters.find(
        (f) => f.employeeId == state.me.id
      );
      const newEmployeeFilter = {
        employeeId: state.me.id,
        filters: filters,
      };
      if (employeeFilters) {
        Object.assign(employeeFilters, newEmployeeFilter);
      } else {
        state.employeeFilters.push(newEmployeeFilter);
      }
      store("employeeFilters", JSON.stringify(state.employeeFilters || []));
    },
    change_employee_headers(state, headers) {
      const employeeHeaders = state.employeeHeaders.find(
        (f) => f.employeeId == state.me.id
      );
      const newEmployeeHeaders = {
        employeeId: state.me.id,
        headers: headers,
      };
      if (employeeHeaders) {
        Object.assign(employeeHeaders, newEmployeeHeaders);
      } else {
        state.employeeHeaders.push(newEmployeeHeaders);
      }
      store("employeeHeaders", JSON.stringify(state.employeeHeaders || []));
    },
    show_all(state, showAll) {
      Vue.set(state, "showAll", showAll);
      store("showAllEmployees", showAll.departments);
      store("showAllShifts", showAll.shifts);
    },

    logout(state) {
      i18n.locale = state.locale = "et";
      store("token", (state.token = undefined));
      store("me", (state.me = undefined));
      store("locale", undefined);
      store("mentorshipActionCount", (state.mentorshipActionCount = undefined));
      store("passwordChangeRequired", false);
      state.riskAnalysisActionsRemaining = null;
    },
    store_mentorship_action_count(state, mentorshipActionCount) {
      Vue.set(state, "mentorshipActionCount", mentorshipActionCount);
      store("mentorshipActionCount", mentorshipActionCount);
    },
    store_risk_analysis_action_count(state, riskAnalysisActionsRemaining) {
      Vue.set(
        state,
        "riskAnalysisActionsRemaining",
        riskAnalysisActionsRemaining
      );
    },
    store_me(state, me) {
      Vue.set(state, "me", me);
      store("me", JSON.stringify(me));
    },
    change_password_success(state) {
      Vue.set(state, "passwordChangeRequired", false);
      store("passwordChangeRequired", false);
    },
    push_global_message(state, message) {
      Vue.set(state, "globalMessages", [...state.globalMessages, message]);
    },
    remove_global_message(state) {
      Vue.set(state, "globalMessages", [...state.globalMessages.slice(0, -1)]);
    },
  },

  actions: {
    async login({ commit, dispatch, state }, data) {
      const response = await client.mutate({
        mutation: LoginQuery,
        variables: data,
      });
      commit("auth_success", response.data.login);
      if (state.me) {
        dispatch("update_risk_analysis_action_count");
      }
    },
    async update_risk_analysis_action_count({ commit, state }) {
      if (!state.me) {
        return Promise.resolve();
      }
      const response = await client.query({
        query: EmployeeRiskAnalysisActionsRemainingQuery,
      });
      commit(
        "store_risk_analysis_action_count",
        response.data.employeeRiskAnalysisActionsRemaining
      );
    },
    change_locale({ commit, state }, loc) {
      commit("change_locale", loc);
      if (!state.me) {
        return Promise.resolve();
      }
      return client.mutate({
        mutation: ChangeLanguageQuery,
        variables: {
          language: loc,
        },
      });
    },
    change_employee_filters({ commit }, filters) {
      commit("change_employee_filters", filters);
    },
    change_employee_headers({ commit }, headers) {
      commit("change_employee_headers", headers);
    },
    show_all({ commit }, showAll) {
      commit("show_all", showAll);
    },
    logout({ commit }) {
      return client
        .clearStore()
        .then(() => commit("logout"))
        .finally(() => router.push("/login"));
    },
  },

  getters: {
    locales: (state) =>
      state.locales.sort((a, b) => {
        if (a.value == defaultLocale) return -1;
        if (b.value == defaultLocale) return 1;
        return 0;
      }),
    isAuthenticated: (state) => state.me && state.me.id >= 0,
    role: (state) => (...roles) =>
      state.me && roles.indexOf(state.me.role) >= 0,
    employeeFilters: (state) => {
      const employeeFilters = state.employeeFilters.find(
        (f) => f.employeeId == state.me.id
      );
      return (employeeFilters && employeeFilters.filters) || [];
    },
    employeeHeaders: (state) => {
      const employeeHeaders = state.employeeHeaders.find(
        (f) => f.employeeId == state.me.id
      );
      return (employeeHeaders && employeeHeaders.headers) || [];
    },
    showAllEmployees: (state) =>
      [
        "admin",
        "safetyRepresentative",
        "manager",
        "departmentManager",
      ].includes(state.me.role) && state.showAll.departments,
    showAllShifts: (state) => state.showAll.shifts,
  },
});
