import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary:
          (window.appSettings && window.appSettings.themePrimaryColor) ||
          "#126DF5",
      },
      dark: {
        primary:
          (window.appSettings && window.appSettings.themePrimaryColor) ||
          "#126DF5",
      },
    },
    icons: {
      iconfont: "md",
    },
  },
});
