import Vue from "vue";
import Router from "vue-router";
import store from "./store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  // scrollBehavior(to, savedPosition) {
  //   if (
  //     to.name === "risk-analysis-department" ||
  //     to.name === "risk-analysis-work-area"
  //   ) {
  //     return { x: 0, y: 0 };
  //   }
  //   return savedPosition || null;
  // },
  routes: [
    {
      name: "home",
      path: "/",
      redirect: () => {
        return {
          name: store.getters.role("employee") ? "profile" : "employees",
        };
      },
    },
    {
      name: "login",
      path: "/login",
      component: () => import("./components/Login"),
      meta: { accessibleWithoutAuth: true },
    },
    {
      name: "passwordChange",
      path: "/passwordChange",
      component: () => import("./components/PasswordChange"),
    },
    {
      name: "employees",
      path: "/employees",
      component: () => import("./components/Employees"),
    },
    {
      name: "employee",
      path: "/employees/:id",
      component: () => import("./components/Employee"),
      props: true,
    },
    {
      name: "profile",
      path: "/profile",
      component: () => import("./components/Profile"),
    },
    {
      name: "instructions",
      path: "/instructions",
      component: () => import("./components/Instructions"),
    },
    {
      name: "instruction",
      path: "/instructions/:id",
      component: () => import("./components/Instruction"),
    },
    {
      name: "reports",
      path: "/reports",
      component: () => import("./components/Reports"),
    },
    {
      name: "management",
      path: "/management",
      component: () => import("./components/Management"),
    },
    {
      name: "mentorship-feedback",
      path: "/mentorship/feedback/:id",
      component: () => import("./components/mentorship/MentorshipFeedbackForm"),
    },
    {
      name: "mentorship-feedback-assigned",
      path: "/mentorship/feedback/assigned/:id",
      props: { isAssigned: true },
      component: () => import("./components/mentorship/MentorshipFeedbackForm"),
    },
    {
      name: "mentorship-actions",
      path: "/mentorship/actions/:id",
      component: () => import("./components/mentorship/MentorshipActionForm"),
    },
    {
      name: "mentorship-actions-assigned",
      path: "/mentorship/actions/assigned/:id",
      props: { isAssigned: true },
      component: () => import("./components/mentorship/MentorshipActionForm"),
    },
    {
      name: "mentorship-employee",
      path: "/mentorship/employees/:id",
      component: () => import("./components/mentorship/MentorshipEmployee"),
      props: true,
    },
    {
      name: "mentorship",
      path: "/mentorship",
      component: () => import("./components/Mentorship"),
    },
    {
      name: "mentorship-ongoing",
      path: "/mentorship/ongoing",
      component: () => import("./components/mentorship/OngoingMentorships"),
      beforeEnter: (to, from, next) => {
        if (store.getters.role("admin", "safetyRepresentative")) {
          next();
        } else {
          next({ name: "mentorship" });
        }
      },
    },

    {
      name: "external-trainings",
      path: "/external-trainings",
      component: () => import("./components/ExtTrainings"),
    },
    {
      name: "unsubscribe",
      path: "/unsubscribe",
      redirect: () => "/notification-settings",
    },
    {
      name: "notification-settings",
      path: "/notification-settings",
      component: () => import("./components/NotificationSettings"),
    },
    {
      name: "risk-analysis",
      path: "/risk-analysis",
      component: () => import("./components/RiskAnalysis"),
    },
    {
      name: "risk-analysis-department",
      path: "/risk-analysis/parentTagId/:tagId",
      component: () => import("./components/riskAnalysis/WorkAreasTable"),
      props: true,
    },
    {
      name: "risk-analysis-history",
      path: "/risk-analysis/childTagId/:childTagId/history",
      component: () => import("./components/riskAnalysis/RiskAnalysisHistory"),
      props: true,
    },
    {
      name: "single-analysis",
      path: "/risk-analysis/:riskAnalysisId",
      component: () => import("./components/riskAnalysis/SingleAnalysis"),
      props: true,
    },
    {
      path: "*",
      redirect: () => "/",
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (
    to.path === "/passwordChange" &&
    from.path !== "/login" &&
    store.state.passwordChangeRequired &&
    store.getters.isAuthenticated
  ) {
    store.dispatch("logout");
  }
  if (
    to.matched.some((route) => !route.meta.accessibleWithoutAuth) &&
    !store.getters.isAuthenticated
  ) {
    return next({ name: "login", query: { from: window.location.pathname } });
  }
  if (
    !to.matched.some((route) => route.path === "/passwordChange") &&
    store.state.passwordChangeRequired &&
    store.getters.isAuthenticated
  ) {
    return next("/passwordChange");
  }
  next();
});

export default router;
