<template>
  <v-app :style="cssProps">
    <toolbar :pathName="pathName" :isMainView="isMainView"></toolbar>

    <v-content
      :style="
        isSecondToolbarVisible
          ? 'padding: 112px 0px 0px'
          : 'padding: 64px 0px 0px'
      "
    >
      <v-container fill-height fluid style="align-items: start !important">
        <router-view></router-view>
      </v-container>
    </v-content>

    <toasts></toasts>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import Toolbar from "./components/Toolbar";
import Toasts from "./components/Toasts";

import EmployeeMentorshipActionCountQuery from "@/graphql/queries/employee/EmployeeMentorshipActionCount.gql";

export default {
  name: "App",
  components: {
    Toolbar,
    Toasts,
  },
  data() {
    return {
      pathName: this.$route.name || "/",
      mentorshipActionCount: 0,
    };
  },
  watch: {
    $route(to) {
      this.pathName = to.name;
    },
  },
  computed: {
    ...mapGetters(["role"]),
    ...mapState(["me", "riskAnalysisActionsRemaining"]),
    cssProps() {
      return {
        "--primary-color": this.$vuetify.theme.themes.light.primary,
      };
    },
    isMainView() {
      return [
        "reports",
        "profile",
        "employees",
        "employee",
        "external-trainings",
        "instructions",
        "management",
        "mentorship",
        "mentorship-ongoing",
        "mentorship-employee",
        "mentorship-feedback",
        "mentorship-feedback-assigned",
        "mentorship-actions",
        "mentorship-actions-assigned",
        "risk-analysis",
        "risk-analysis-department",
        "risk-analysis-history",
        "single-analysis",
      ].includes(this.pathName);
    },
    isSecondToolbarVisible() {
      return (
        this.isMainView &&
        (this.role(
          "manager",
          "departmentManager",
          "admin",
          "safetyRepresentative"
        ) ||
          this.mentorshipActionCount ||
          this.riskAnalysisActionsRemaining !== null)
      );
    },
  },
  mounted() {
    if (this.me) {
      this.$store.dispatch("update_risk_analysis_action_count");
    }
  },
  methods: {
    checkIfNeedUpdateUserInformation(employee) {
      const requiredKeys = ["id", "firstName", "lastName", "role", "language"];

      const newEmployee = {};
      for (const key of requiredKeys) {
        if (!(key in employee)) {
          /* eslint-disable no-console */
          console.error(`key ${key} is missing in employee ${this.id} object`);
          return;
        }
        newEmployee[key] = employee[key];
      }

      for (const key of requiredKeys) {
        if (this.me[key] != newEmployee[key]) {
          this.$store.commit("store_me", newEmployee);
          return;
        }
      }
    },
  },
  apollo: {
    employee: {
      query: EmployeeMentorshipActionCountQuery,
      variables() {
        return {
          id: this.me.id,
        };
      },
      update(data) {
        const employee = data.employee;
        this.checkIfNeedUpdateUserInformation(employee);

        const activeMentorshipActionsCount = employee.activeMentorshipActions
          ? employee.activeMentorshipActions.length
          : 0;
        const activeMentorshipsCount = employee.activeMentorships
          ? employee.activeMentorships.length
          : 0;
        const unconfirmedMentorshipsCount = employee.unconfirmedMentorships
          ? employee.unconfirmedMentorships.length
          : 0;

        const mentorshipActionCount =
          activeMentorshipActionsCount +
          activeMentorshipsCount +
          unconfirmedMentorshipsCount;

        this.mentorshipActionCount = mentorshipActionCount;

        this.$store.commit(
          "store_mentorship_action_count",
          mentorshipActionCount
        );

        return data.employee;
      },
      skip() {
        return !this.me;
      },
    },
  },
};
</script>

<style>
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}
.table-striped tbody tr {
  height: 56px;
}
.table-striped td {
  border-bottom: none !important;
}
.table-striped .v-data-table-header {
  height: 48px;
}
.safety-elevation-3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.05),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
}
</style>
